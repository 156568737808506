const countdown = () => {
    const dateTime = {
        month: 'March 1',
        year: '2025',
        time: '12:00:00'
    }
    const finalDate = new Date(`${dateTime.month}, ${dateTime.year} ${dateTime.time}`).getTime(); // Целевая дата

    const timer = setInterval(() => {
        const now = new Date().getTime(); // Текущее время
        const distance = finalDate - now; // Разница между целевой датой и текущим временем

        // Вычисление дней, часов, минут и секунд
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Вывод значений на страницу
        document.getElementById("days").innerText = days;
        document.getElementById("daysLabel").innerText = getDaysLabel(days);
        document.getElementById("hours").innerText = hours.toString().padStart(2, '0');
        document.getElementById("minutes").innerText = minutes.toString().padStart(2, '0');
        document.getElementById("seconds").innerText = seconds.toString().padStart(2, '0');

        // Когда время истекло
        if (distance < 0) {
            clearInterval(timer); // Остановка таймера
            document.querySelector('.home-content__clock').innerHTML = "<span class='subject'>Проект <span>успешно</span> запущен и теперь доступен для использования</span>"; // Сообщение об окончании
        }
    }, 1000); // Обновление каждую секунду (1000 мс)
};

// Функция для правильного склонения слова "Дней"
const getDaysLabel = (days) => {
    if (days % 10 === 1 && days % 100 !== 11) {
        return 'День';
    } else if (days % 10 >= 2 && days % 10 <= 4 && (days % 100 < 10 || days % 100 >= 20)) {
        return 'Дня';
    } else {
        return 'Дней';
    }
};

countdown();

document.addEventListener('DOMContentLoaded', () => {
    const loadResource = (tag, attr, url, onload) => {
        // Добавляем уникальный параметр для предотвращения кеширования
        const cacheBuster = `?v=${Date.now()}`;
        const el = document.createElement(tag);
        el[attr] = `https://www.ai.stores.kg/static/sw-live-chat/${url}${cacheBuster}`;
        if (tag === 'link') el.rel = 'stylesheet'; // Добавляем rel="stylesheet" для <link>
        if (onload) el.onload = onload;
        document.head.appendChild(el);
    };
    loadResource('link', 'href', 'main.css');
    loadResource('script', 'src', 'bundle.js', () => {
        new SWLiveChat({ agent_key: 'agent_86rxyo6Krp6WrSzB241BEA' });
    });
});

var typed = new Typed('.changeTitle', {
    strings: ['помощник', 'продавец', 'оператор'],
    typeSpeed: 70,
    backSpeed: 70,
    backDelay: 2000,
    loop: true,
    loopCount: Infinity,
});